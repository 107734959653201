/* Carousel.css */
.swiper-wrapper{
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.mySwiper img {
    width: 100%;
    height: auto;
}

@media (min-width: 640px) {
    .mySwiper img {
        max-width: 90%;
    }
}

@media (min-width: 768px) {
    .mySwiper img {
        max-width: 90%;
    }
}

@media (min-width: 1024px) {
    .mySwiper img {
        max-width: 70%;
    }
}