@import url(/src/ContentSystem.css);

.badge__video {
    display: none;
}

.badge__contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.badge__video-mobile {
    display: inline;
}

.section__title-badge{
    background-image: linear-gradient(to right, var(--chalk-violet) 20%, var(--bleached-aqua) 40%, var(--shady-glade) 100%);
    display: inline;
    font-size: 30px;
    padding: 20px 0 0 0px;
    white-space: pre-wrap;
    margin: 0px;
    font-family: var(--grotesk-bold);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.span__subtitle-badge{
    color: var(--main-color);
    font-family: var(--grotesk-regular);
    font-size: 20px;
    padding: 10px 15px 10px 0px;
}

.badge__left {}


.badge___right {
    display: flex;
    flex-direction: column;
}
.video-container {
    position: relative;
    width: 100%;
    height: auto;
}

.video-container video {
    border-radius: 32px;
    width: 350px;
    height: auto;
    max-width: 100%;
    position: relative;
    z-index: 0;
}

.badgeCover {
    position: absolute;
    width: auto;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}

@media (min-width:1024px) {

    .badge__video {
        display: inline;
    }

    .badge__video-mobile {
        display: none;
    }

    .badge__contents {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
    }

    /* Left Side */
    .badge___left {
        width: auto;
    }

    .video-container video {
        border-radius: 32px;
        width: 450px;
        height: auto;
        max-width: 100%;
        position: relative;
        z-index: 0;
    }

    /* Right Side */

    .badge__right {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .section__title-badge {
        color: var(--main-color);
    }

    .span__subtitle-badge {
        color: var(--main-color);
        white-space: 1.5px;
    }

    .badge__video {
        display: flex;
        justify-content: center;

        position: relative;
    }

    .badge__video-mobile {
        display: none;
    }

    .video-container {
        position: relative;
        width: 100%;
        height: auto;
    }

    .badgeCover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: cover;
    }

    .badgeLeft {
        position: relative;
        z-index: 0;
        width: 100%;
        height: auto;
    }

}