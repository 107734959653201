.back-to-top {
  align-items: center;
  background-color: var(--shady-glade);
  border: none;
  border-radius: 50%;
  bottom: 115px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  color: var(--main-color);
  cursor: pointer;
  display: flex;
  font-size: 40px;
  height: 50px;
  justify-content: center;
  position: fixed;
  right: 30px;
  transition: opacity 0.4s ease, transform 0.4s ease;
  opacity: 0;
  transform: translateY(20px); 
  width: 50px;
  z-index: 1;
}

.back-to-top.show {
  opacity: 1;
  transform: translateY(0);
  /* Button moves up smoothly when visible */
}

.back-to-top:hover {
  background-color: var(--button-hover);
  transform: translateY(-5px);
}

.ChevronIcon {
  font-size: 20px;
  margin-bottom: 4px;
}
