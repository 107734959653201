@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

:root {
  --legion-blue: #0a2a36;
  --shady-glade: #02735e;
  --light-legion-blue: #07303f;
  --button-content: #14735e;
  --gray-eno: #d9d9d9;
  --black-eno: #040f13;
  --black-eno-mid: #727272;
  --bleached-aqua: #bde0e2;
  --chalk-violet: #9183a4;
  --chalk-violet-strong: #894cdf;
  --contract-color: #009268;
  --golden-kiwi: #f2d541;
  --main-color: #ffffff;
  --button-hover: #049a6d;
  --blue-vortex: #101a29;
  --section-title: 25px;
  --section-subtitle: 20px;
  --text--subtitle: 22px;
  --typeface: "Stop Normal TypeFace", sans-serif;
  --grotesk-regular: "Grotesk Regular", sans-serif;
  --grotesk-semi-bold: "Grotesk Semi Bold", sans-serif;
  --grotesk-bold: "Grotesk Bold", sans-serif;
  --mrg-top: 50px;
  --mrg-top-mid: 100px;
  --mrg-top-big: 150px
    /* margins */

    --mrg-top: 50px;
  --mrg-top-mid: 100px;
  --mrg-top-big: 150px;
}

@font-face {
  font-family: "Grotesk Bold";
  src: url("../fonts/FoundersGrotesk-Bold.ttf");
}

html {
  scroll-behavior: smooth;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  color: var(--textColor);
  background-color: var(--light-legion-blue);
  position: sticky;
  /* Cambiado de fixed a sticky */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  animation: moveDown 0.5s ease-in-out;
}

.logo__header img {
  width: 120px;
}

@keyframes moveDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

nav a {
  margin: 0 1rem;
  color: var(--main-color);
  text-decoration: none;
  font-size: 19px;
  font-family: var(--grotesk-bold);
}

nav a:hover {
  color: transparent;
  background-image: linear-gradient(to right, var(--chalk-violet) 20%, var(--bleached-aqua) 40%, var(--shady-glade) 100%);
  background-clip: text;
  -webkit-background-clip: text;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--main-color);
  display: none;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}


.btn-alternative-navbar{
  background-color: var(--shady-glade);
  border-radius: 32px;
  font-size: 14px;
  width: 120px;
}

.btn-alternative-navbar:hover {
  background-color: var(--button-hover);
}


.btn-alternative{
  background-color: var(--shady-glade);
  border-radius: 32px;
  font-size: 18px;
  width: 250px;
}

.btn-alternative:hover{
  background-color: var(--button-hover);
}

@media only screen and (min-width: 1024px) {

  .btn-alternative-navbar {
    background-color: var(--shady-glade);
    border-radius: 32px;
    font-size: 16px;
    width: 200px;
  }

  .btn-alternative-navbar:hover {
    background-color: var(--button-hover);
  }

}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    display: inline;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    backdrop-filter: blur(23px);
    -webkit-backdrop-filter: blur(23px);
    background-color: #0a2a3675;
    transition: right 0.5s;
  }

  header .responsive_nav {
    right: 0;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
  h2 img {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  nav a {
    font-size: 18px;
    transition: color 0.3s;
  }
}


@media only screen and (min-width: 1900px) {

  nav a {
    color: var(--main-color);
    transition: color 0.3s;
  }

  nav a:hover {
    color: transparent;
    background-image: linear-gradient(to right, var(--chalk-violet) 20%, var(--bleached-aqua) 40%, var(--shady-glade) 100%);
    background-clip: text;
    -webkit-background-clip: text;
  }

  header .nav-btn {
    color: var(--mainColor);
  }
}