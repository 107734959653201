
.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color);
    flex-direction: row-reverse;
    padding-top:20px;
   /*  border-bottom: 0.1px solid var(--black-eno-light); */
}

button.close-button {
    color: var(--black-eno);
    display: flex;
    padding-top:5px;
    background-color: transparent;
    font-size:20px!important;
    /* border: 1px solid black; */
}

.privacyPolicy a{
    text-decoration: none;
}

.privacyPolicy p{
    margin-top: 15px;
    font-size: 12px;
    color: var(--black-eno-mid);
}

.title-left {
    width: 90%;
}

.title-right {
    display: flex;
    width: 15%;
    justify-content: flex-end;
}

.chat-button {
    width: 50px;
    height: 50px;
    background-color: #02735e;
    color: var(--main-color);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px; /* Añade espacio entre el botón y el texto */
    padding-top: 0px;
    padding-bottom:8px;
    padding-left: 0px;
    padding-right: 0px;
}

.chat-button img {
    width: 28px;
}

.chat-label {
    background-color: white;
    color: #02735e; /* Color que coincida con el botón para mejor visibilidad */
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    white-space: nowrap; /* Evita que el texto se divida en múltiples líneas */
    cursor: pointer;
}

.chat-button img{
    width: 28px;
}

.chat-button:hover {
    background-color: #02735e!important;
}

.chat-button:active {
    transform: scale(0.95);
}

.chat-button.active {
}

.chat-window {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 90%;
    max-width: 360px;
    height: 80%;
    max-height: 520px;
    background-color: var(--legion-blue);
    border-radius: 15px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
    display: none;
    flex-direction: column;
    overflow: hidden;
    z-index: var(--z-fixed);
    transition: transform 0.3s, opacity 0.3s;
    transform: scale(0.95);
    opacity: 0;
}

.chat-window.open {
    display: flex;
    transform: scale(1);
    opacity: 1;
    bottom: 150px;
    border-radius: 32px;
}

.chat-header {
    color: var(--black-eno);
    margin-right: 15px;
    padding: 0px 0;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    display: flex;
}

.closeIcon-chat {
    display: none;
}

.closeIcon-x {
    color: var(--main-color);
}

.closeIcon-chevron {
    display: inline;
    color: var(--black-enos);
    width: 40px;
    padding-top: 5px;
}

.chat-body {
    display: flex;
    flex-direction: column;
    padding-top:0px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;
    background-color: var(--main-color);
    color: var(--main-color);
    scrollbar-width: thin;
    scrollbar-color: var(--shady-glade);
    height: 100vh;
}

.chat-footer {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: var(--main-color);
    align-items: center;
}

input.chat-input::placeholder {
    color: var(--black-eno-light);
    font-size: 14px;
}

.chat-input {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    background-color: #f4f4f4;
    color: #494949;
    transition: border-color 0.3s, box-shadow 0.3s;
}

input.chat-input {
    background-color: #f4f4f4 !important;
}

.chat-input:focus {
    border-color: var(--golden-kiwi);
    box-shadow: 0 0 5px var(--black-eno-mid);
}

.chat-send-button {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 32px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    justify-content: center;
}

.chat-message {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s, opacity 0.3s;
    opacity: 0;
    transform: translateY(20px);
    animation: slide-in 0.5s forwards;
}

.chat-message.user {
    background-color: var(--shady-glade);
    border-radius: 16px 20px 0px 16px;
    align-self: flex-end;
}

.chat-message.error {
    background-color: var(--black-eno-mid);
    border-radius: 16px 16px 20px 0px;
    align-self: flex-start;
    text-align: left;
    width: auto;
    max-width: 80%;
}

.chat-message.agnes {
    background-color: var(--main-bone);
    color: var(--black-eno);
    border-radius: 20px 16px 16px 0px;
    align-self: flex-start;
    text-align: left;
    width: auto;
    max-width: 80%;
}

/* Agnes Info */

.AgnesInitialContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.agnesImage {
    height: 170px;
}

.AgnesInitialContainer h2 {
    margin-top: 20px;
    color: var(--black-eno);
    font-family: var(--grotesk-bold);
}

.AgnesInitialContainer span {
    color: var(--black-eno-mid);
    font-family: var(--grotesk-regular);
}

.alpha{
    font-style: italic; 
    padding-top: 0px!important;
    margin-top: -20px!important;
    margin-bottom: 10px!important;
    font-size: 20px;
}


    /* Agnes Grid Content */

    .dataAgnes {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .dataAgnesInfo {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        align-items: center;
        width: 250px;
        justify-content: space-between;
    }

    .AgnesRight h3,
    p {
        color: var(--black-eno);
    }

    .GridLeft {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .GridLeft h3 {
        font-size: 16px;
        font-family: var(--grotesk-bold);
        color: var(--black-eno);
        margin: 0px;
    }

    .GridLeft p {
        font-size: 15px;
        font-family: var(--grotesk-regular);
        color: var(--black-eno);
        margin: 0px;
    }

    .AgnesIcon {
        height: 40px;
    }

    .ChatButtonIcon{
        width: 30px;
    }

/* End Agnes Info */

@keyframes slide-in {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (min-width: 768px) {
    .chat-button {
        bottom: 20px;
        right: 20px;
    }

    .chat-window {
        bottom: 100px;
        right: 40px;
        width: 360px;
        height: 520px;
    }

    .chat-body{
        padding: 20px;
    }

    .title-container{
        padding-top:0px;
    }
}

@media (min-width: 1024px) {
    .chat-header {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--shady-glade);
        color: var(--main-color);
        padding-top: 20px;
        height: 50px;
        font-size: 22px;
        font-family: var(--grotesk-bold);
    }

    .chat-button {
        bottom: 20px;
        right: 20px;
    }

    .chat-window {
        bottom: 110px;
        right: 50px;
    }

    .chat-window.open {
        display: flex;
        transform: scale(1);
        opacity: 1;
        bottom: 100px;
    }
}

@media (max-width: 1023px) {
    .chat-window {
        width: 100vw;
        /* 100% of viewport width */
        height: 100vh;
        /* 100% of viewport height */
        right: 0;
        bottom: 0;
        border-radius: 0;
        /* Remove border-radius for full screen effect */
        max-width: none;
        /* Ensure no maximum width */
        max-height: none;
        /* Ensure no maximum height */
    }

    .chat-window.open {
        bottom: 0;
        right: 0;
        transform: scale(1);
        opacity: 1;
        border-radius: 0;
    }
}

/* Desktop: retain current properties */
@media (min-width: 1024px) {
    .chat-window {
        bottom: 100px;
        right: 50px;
        width: 360px;
        height: 520px;
    }

    .chat-window.open {
        bottom: 15px;
        right: 100px;
        transform: scale(1);
        opacity: 1;
        border-radius: 32px;
    }

    .title-container {
        display: flex;
        flex-direction: row;
        background-color: var(--shady-glade);
    }

    .chat-header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: var(--main-color);
        padding-top: 20px;
        height: 20px;
        font-size: 22px;
        width: 85%;
        border-radius: 0;
        font-family: var(--grotesk-regular);
    }

    .title-left {
        width: 100%;
    }

    .title-right {
        display: flex;
        font-size: 20px;
        padding-right: 7px;
        justify-content: flex-end;
        width: 15%;
    }

    .closeIcon-x {
        color: var(--main-color);
    }

    .closeIcon-chevron {
        display: none;
    }

    .AgnesInitialContainer {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .agnesImage {
        height: 150px;
    }

    .AgnesInitialContainer h2 {
        margin-top: 5px;
        color: var(--black-eno);
        font-family: var(--grotesk-bold);
    }

    .AgnesInitialContainer span {
        color: var(--black-eno-mid);
        font-family: var(--grotesk-regular);
    }

    /* Agnes Grid Content */

    .dataAgnes {
        display: flex;
        align-items: center;
        flex-direction: row;
        row-gap: 10px;
        margin-top: 5px;
        width: 300px;
        justify-content: center;
    }

    .dataAgnesInfo {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 0;
    }

    .AgnesRight h3,
    p {
        color: var(--black-eno);
    }

    .GridLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .GridLeft h3 {
        font-size: 14px;
        font-family: var(--grotesk-semi-bold);
        color: var(--black-eno);
        ;
    }

    .GridLeft p {
        font-size: 13px;
        font-family: var(--grotesk-regular);
        color: var(--black-eno-mid);
        text-align: center;
        line-height: 14px;
    }

    .AgnesIcon {
        height: 30px;
    }

    .AgnesRight {
        display: flex;
        width: 50px;
        height: 50px;
        background-color: var(--main-bone);
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
    
}


@media screen and (max-width: 767px) {
    .title-right {
        position: fixed;
        top: 75px;
        left: 20px;
        width: auto;
        z-index: 1002;

    }

    /* Si el botón está dentro de un contenedor con posición relativa, 
       asegúrate de que el contenedor tenga un z-index menor */
    .chat-window {
        z-index: 1001;
    }

    .closeIcon-x{
        display: none;
    }
}

.chat-button-container:active .chat-button {
    transform: scale(0.95);
}

.chat-button-container.active .chat-button {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

.chat-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: var(--z-fixed);
}

.chat-button-container:hover {
    transform: scale(1.05);
}

.chat-button-container:hover .chat-label {
    background-color: #f0f0f0; /* Cambia ligeramente el color de fondo al pasar el mouse */
}

@media (max-width: 767px) {
    .chat-button-container {
        bottom: 20px;
        right: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .chat-button-container {
        bottom: 20px;
        right: 20px;
    }
}

@media (min-width: 1024px) {
    .chat-button-container {
        bottom: 20px;
        right: 20px;
    }
}

.chat-label:hover {
    background-color: #f0f0f0;
}