/*==================== VARIABLES ====================*/
:root {
    --legion-blue: #0a2a36;
    --shady-glade: #02735e;
    --light-legion-blue: #07303f;
    --button-content: #14735e;
    --gray-eno: #d9d9d9;
    --black-eno: #040f13;
    --black-eno-mid: #727272;
    --black-eno-gray: #2c2c2d;
    --bleached-aqua: #bde0e2;
    --chalk-violet: #9183a4;
    --chalk-violet-strong: #894cdf;
    --contract-color: #009268;
    --golden-kiwi: #f2d541;
    --main-color: #ffffff;
    --main-bone:#E5E5E5;
    --button-hover: #049a6d;
    --blue-vortex: #101a29;
    --section-title: 25px;
    --section-subtitle: 20px;
    --text--subtitle: 22px;
    --typeface: "Stop Normal TypeFace", sans-serif;
    --grotesk-regular: "Grotesk Regular", sans-serif;
    --grotesk-semi-bold: "Grotesk Semi Bold", sans-serif;
    --grotesk-bold: "Grotesk Bold", sans-serif;
    /* z-index */
    --z-fixed: 100;
}

@font-face {
    font-family: "Stop Normal TypeFace";
    src: url("../fonts/Stop Normal TypeFace.ttf");
}

@font-face {
    font-family: "Grotesk Regular";
    src: url("../fonts/FoundersGrotesk-Regular.ttf");
}

@font-face {
    font-family: "Grotesk Semi Bold";
    src: url("../fonts/FoundersGrotesk-Semibold.ttf");
}

@font-face {
    font-family: "Grotesk Medium";
    src: url("../fonts/FoundersGrotesk-Medium.ttf");
}

@font-face {
    font-family: "Grotesk Bold";
    src: url("../fonts/FoundersGrotesk-Bold.ttf");
}

Mobile

/* ========== MyBadges ========== */
.badge-grid-list {
    grid-template-columns: 1;
}

/*==================== MEDIA QUERIES ====================*/

@media (max-width: 1024px) and (min-width: 768px) {

    /* ========== BadgeMint ========== */
    .badge-grid-list {
        grid-template-columns: repeat(3, 1fr);
    }
}


@media (min-width: 768px) and (max-width: 1023px) {

    /* ========== MyNFT ========== */
    .nft-gridNFT {
        grid-template-columns: repeat(2, 1fr);
    }

    /* ========== NFTPurchase ========== */
    .nft-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 0 20px;
    }

    /* ========== MintBadge ========== */
    .nft-grid-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 0 20px;
    }
}

@media (max-width: 768px) {

    /* ========== MyBadges ========== */
    .badge-grid-list {
        grid-template-columns: repeat(2, 1fr);
    }

    /* ========== BadgeMint ========== */
    .badge-grid-list {
        grid-template-columns: repeat(3, 1fr);
    }
}


@media (min-width: 1024px) {

    .whiteBackground {
        background-color: var(--main-color);
      }

    /* ========== MyNFT ========== */
    .nft-gridNFT {
        grid-template-columns: repeat(3, 1fr);
    }

    /* ========== NFTPurchase ========== */
    .nft-grid-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        padding: 0 20px;
    }

    /* ========== NFTPurchase ========== */
    .nft-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        padding: 0 20px;
    }

    /* ========== BadgeMint ========== */

    /* ========== MyBadges ========== */
    .badge-grid-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        padding: 0;
    }

}

@media (max-width: 1024px) {

    /* ========== MyBadges ========== */
    .badge-grid-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 0;
    }

    /* ========== BadgeMint ========== */
    .badge-grid-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1024px) and (min-width: 768px) {

    /* ========== BadgeMint ========== */
    .badge-grid-list {
        grid-template-columns: repeat(3, 1fr);
    }

    /* ========== MyBadges ========== */
    .badge-grid-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .badge-grid-list {
        grid-template-columns: 1fr; 
    }
}