/*==================== Import CSS Global Styles ====================*/
@import url(./globalStyles.module.css);

/* ==================== Clases alternativas ==================== */
.bulletContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -24px;
}

.bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--black-eno-mid);
  margin: 0 5px;
  transition: background-color 0.3s;
}

.bullet.active {
  background-color: var(--main-color);
}

.latestNews {
  padding: 10px;
}

.latestContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.headerContainer {
  background-color: var(--light-legion-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 32px;
}

.headerContainer h2 {
  font-size: 20px;
  font-family: var(--grotesk-bold);
  color: var(--main-color);
}

/* End Option One */

.headerContainerOne {
  background-color: var(--main-bone);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 32px;
  width: 100%;
}

.headerContainerOne h2 {
  font-size: 20px;
  font-family: var(--grotesk-bold);
  color: var(--black-eno);
}

.BlogsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 32px;
  width: 100%;
}

.BlogsContainer h2 {
  font-size: 20px;
  font-family: var(--grotesk-bold);
  color: var(--main-color);
}

.latestContainerOne {
  background-color: var(--main-bone);
  border-radius: 32px;
  padding: 15px 15px 45px 15px;
}

.viewAllBlogsBtnOne {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--shady-glade);
  color: var(--main-color);
  border-radius: 22px;
  font-family: var(--grotesk-semi-bold);
  font-size: 16px;
  padding: 10px 0;
  width: 150px;
  transition: all 0.2s ease-in-out;
}

.viewAllBlogsBtnOne:hover {
  background-color: var(--button-hover);
  color: #fff;
}

/* End Option One */

.viewAllBlogsBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--shady-glade);
  border-radius: 22px;
  color: var(--main-bone);
  font-family: var(--grotesk-semi-bold);
  font-size: 16px;
  padding: 10px 0;
  width: 150px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.viewAllBlogsBtn:hover {
  background-color: var(--button-hover);
  color: #fff;
}

.postsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #ccc;
  border-radius: 16px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  width: 350px;
  min-height: 520px;
  box-sizing: border-box;
  cursor: grab;
}

.postImage {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.postTitle {
  font-family: var(--grotesk-bold);
  color: var(--black-eno);
  font-size: 20px;
  line-height: 1.2;
  margin-top: 10px;
  text-align: left;
  text-transform: capitalize;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: visible;
  flex-grow: 1;
  /* Para llenar el espacio disponible */
}

.postDate {
  display: flex;
  background-color: var(--chalk-violet);
  border-radius: 16px;
  color: var(--main-color);
  font-weight: 700;
  font-size: 14px;
  font-family: var(--grotesk-regular);
  letter-spacing: 1px;
  margin-top: 15px;
  height: 40px;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.postsSwiper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.postSlide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.postExcerpt {
  margin: 8px 0;
  color: var(--black-eno-mid);
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Limitar a 3 líneas */
  -webkit-box-orient: vertical;
  flex-grow: 1;
  /* Asegurar que la descripción ocupe el espacio disponible */
}

.heroBtn {
  cursor: pointer;
  border: none;
  background-size: 300% 100%;
  width: 100%;
  font-size: 18px;
  font-weight: 800;
  font-family: var(--grotesk-bold);
  color: var(--black-eno);
  text-align: center;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.4s ease-in-out;
}

.heroBtn a {
  text-decoration: none;
  color: var(--main-color);
}

.heroBtn:hover {
  background-position: 100% 0;
  transition: all 0.4s ease-in-out;
}

.heroBtn:focus {
  outline: none;
}

.heroBtnColor {
  background-color: var(--shady-glade);
  color: var(--main-color);
  padding: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroBtnColor:hover {
  background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
  box-shadow: 0 0 15px 0 var(--golden-kiwi);
  border: 2px solid var(--golden-kiwi);
  color: var(--main-color);
}

.btnContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  /* Empujar el botón hacia abajo */
}

@media (min-width: 1024px) {
  .latestNews {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically if needed */
    padding: 20px;
    border-radius: 32px;
    max-width: 1920px;
    margin: 0 auto;
  }

  .latestContainer {
    background-color: var(--light-legion-blue);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px 25px 50px 25px;
  }

  .viewAllBlogsBtn {
    background-color: var(--main-color);
    border-radius: 240px;
    color: var(--shady-glade);
    font-family: var(--grotesk-semi-bold);
    font-size: 16px;
    padding-top: 12px;
    transition: all 0.4s ease-in-out;
  }

  .headerContainer {
    padding-top: 20px;
    width: 100%;
    max-width: 1920px;
  }

  .headerContainer h2 {
    font-family: var(--grotesk-bold);
    font-size: 35px;
  }

  .postsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 32px;
  }

  .post {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    font-family: var(--grotesk-regular);
    margin-bottom: 0px;
    line-height: 17px;
    border-radius: 25px;
    width: 360px;
    color: var(--black-eno-mid);
    min-height: 500px;
    /* Fijar la altura mínima */
    cursor: pointer;
  }

  .postImage {
    max-width: 100%;
    height: auto;
    border-radius: 16px;
  }

  .postTitle {
    font-family: var(--grotesk-bold);
    color: var(--black-eno);
    font-size: 20px;
    line-height: 1.2;
    margin-top: 20px;
    text-transform: capitalize;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: visible;
    flex-grow: 1;
    /* Espacio para el título */
  }

  .postDate {
    display: flex;
    background-color: var(--chalk-violet);
    border-radius: 16px;
    color: var(--main-color);
    font-weight: 700;
    font-size: 16px;
    font-family: var(--grotesk-regular);
    letter-spacing: 1px;
    margin-top: 15px;
    height: 35px;
    width: 45%;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
  }

  .postExcerpt {
    flex-grow: 1;
  }

  .readNowButton {
    align-self: center;
  }

  .heroBtnColor {
    background-color: transparent;
    border: 2px solid var(--black-eno);
    color: var(--black-eno);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heroBtnColor:hover {
    background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
    box-shadow: 0 0 15px 0 var(--golden-kiwi);
    border: 2px solid var(--golden-kiwi);
    color: var(--main-color);
  }


  .btnContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .bulletContainer {
    display: none;
  }
}

@media (max-width:1024) {
  .postTitle {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .post {
    width: calc(50% - 20px);
    /* Ajuste para tablets */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .posts-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Dos columnas con igual ancho */
    gap: 20px;
    /* Espacio entre las columnas */
  }

  .post {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    font-family: var(--grotesk-regular);
    margin-bottom: 0px;
    line-height: 17px;
    border-radius: 25px;
    width: 300px;
    /* Asegura que cada .post ocupe el ancho completo de su columna */
    color: var(--black-eno-mid);
    min-height: 500px;
    /* Fijar la altura mínima */
    cursor: pointer;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .latestContainer,
  .headerContainer,
  .swiper-horizontal {
    width: 100% !important;
  }

  .postTitle {
    font-size: 20px;
  }
}