:root {
	--legion-blue: #0a2a36;
	--shady-glade: #02735e;
	--light-legion-blue: #07303f;
	--button-content: #14735e;
	--gray-eno: #d9d9d9;
	--black-eno: #040f13;
	--black-eno-mid: #727272;
	--bleached-aqua: #bde0e2;
	--chalk-violet: #9183a4;
	--chalk-violet-strong: #894cdf;
	--contract-color: #009268;
	--golden-kiwi: #f2d541;
	--main-color: #ffffff;
	--button-hover: #049a6d;
	--blue-vortex: #101a29;
	--section-title: 25px;
	--section-subtitle: 20px;
	--text--subtitle: 22px;
	--typeface: "Stop Normal TypeFace", sans-serif;
	--grotesk-regular: "Grotesk Regular", sans-serif;
	--grotesk-semi-bold: "Grotesk Semi Bold", sans-serif;
	--grotesk-bold: "Grotesk Bold", sans-serif;
	--mrg-top: 50px;
	--mrg-top-mid: 100px;
	--mrg-top-big: 150px
}

@font-face {
	font-family: "Stop Normal TypeFace";
	src: url("./fonts/Stop Normal TypeFace.ttf")
}

@font-face {
	font-family: "Grotesk Regular";
	src: url("./fonts/FoundersGrotesk-Regular.ttf")
}

@font-face {
	font-family: "Grotesk Medium";
	src: url("./fonts/FoundersGrotesk-Medium.ttf")
}

@font-face {
	font-family: "Grotesk Semi Bold";
	src: url("./fonts/FoundersGrotesk-Semibold.ttf")
}

@font-face {
	font-family: "Grotesk Bold";
	src: url("./fonts/FoundersGrotesk-Bold.ttf")
}

.app__container,
.navbar {
	overflow-x: hidden
}

.hero__btn,
.hero__btn a {
	text-decoration: none;
	color: var(--main-color)
}

.hero__btn,
.hero__btn a,
.hero__paragraph,
.hero__title,
.span__hero {
	color: var(--main-color)
}

.hero__btn,
.hero__btn:hover {
	-o-transition: .4s ease-in-out;
	-webkit-transition: .4s ease-in-out
}

.contract__subtitle:hover,
.contract__subtitle__contract a:hover {
	color: var(--golden-kiwi);
	color: var(--golden-kiwi)
}

body {
	background-color: var(--legion-blue);
	margin: 0;
	padding: 0;
	font-family: Arial, sans-serif;
	overflow-x: hidden !important
}

.app__container {
	position: relative
}

::-webkit-scrollbar {
	width: 10px
}

.hero__btn,
.hero__logo {
	width: 250px
}

::-webkit-scrollbar-thumb {
	background: linear-gradient(var(--contract-color), var(--chalk-violet));
	border-radius: 16px
}

.hero__title,
.section__title,
.section__title-membership {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent
}

.section__title {
	background-image: linear-gradient(to right, var(--chalk-violet) 20%, var(--bleached-aqua) 40%, var(--shady-glade) 100%);
	display: inline;
	font-size: 30px;
	font-family: var(--grotesk-bold)
}

.core__main,
.hero__container,
.token__contract__left {
	display: flex;
	flex-direction: column;
	align-items: center
}

.hero__content,
.hero__left,
.media__content,
.token__content {
	flex-direction: column;
	display: flex
}

section.hero__container {
	margin-top: 50px;
}

.hero__paragraph {
	font-size: 20px;
	font-weight: 900;
	line-height: 10px
}

.hero__title {
	font-size: 45px;
	font-family: var(--typeface);
	background-image: linear-gradient(to right, var(--chalk-violet) 30%, var(--bleached-aqua) 40%, var(--contract-color) 60%);
	margin: 25px 0;
	padding: 0 50px
}

.span__hero {
	font-size: 18px;
	padding: 0 50px
}

.hero__left {
	align-items: center;
	margin-top: 80px
}

.hero__right img {
	width: 100%;
	max-width: 800px
}

.hero__btn {
	font-size: 25px;
	font-weight: 800;
	font-family: var(--grotesk-bold);
	padding: 18px 0 12px;
	text-align: center;
	border-radius: 50px
}

.hero__btn.color-1,
.hero__btn.color-2 {
	background-color: transparent;
	border: 2px solid #fff
}

.hero__btn.color-1:hover,
.hero__btn.color-2:hover {
	background-image: linear-gradient(to right, var(--button-hover), var(--bleached-aqua), var(--legion-blue));
	border: 2px solid var(--golden-kiwi)
}

.hero__boxes a,
.social__icons a {
	height: 16px;
	color: #000;
	background-color: #bde0e2;
	padding: 5px;
	font-size: 16px;
	text-decoration: none
}

.warpIcon,
.MarketIcon,
.Zealy,
.CoinGecko {
	margin-top: 3px;
	width: 16px;
}

.hero__btn:hover {
	background-position: 100% 0;
	transition: .4s ease-in-out
}

.btn:focus,
.hero__btn:focus,
.input:focus,
input:focus {
	outline: 0
}

.hero__btn.color-1 {
	font-size: 20px
}

.hero__btn.color-1:hover {
	box-shadow: 0 4px 15px 0 var(--golden-kiwi)
}

.hero__btn.color-2:hover {
	box-shadow: 0 4px 15px 0 var(--bleached-aqua)
}

.hero__btn__container {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	gap: 20px
}

.hero__boxes,
.social__icons {
	gap: 10px;
	display: flex
}

.hero__icon__container {
	display: flex;
	margin-top: 30px
}

.hero__boxes a {
	display: flex;
	justify-content: center;
	width: 28px;
	align-items: center;
	border-radius: 32px
}

.whyContent {
	margin: 0 0 0 0;
}

.empower {
	margin: 0 0 0 0;
}

.youtube-video {
	border: 1px solid var(--golden-kiwi);
	border-radius: 32px;
	transition: transform .3s, border .3s;
	-webkit-box-shadow: -1px 9px 42px 8px #0b3d3d;
	-moz-box-shadow: -1px 9px 42px 8px #0b3d3d;
	box-shadow: -1px 9px 42px 8px #0b3d3d;
	border: 2px solid transparent;
	width: 90%;
	height: 300px
}

.inputemail {
	margin-bottom: 10px;
	margin-left: 10px
}

.youtube-video:hover {
	border: 2px solid var(--golden-kiwi)
}

.ewsc__logo {
	display: flex;
	margin-top: 40px;
	justify-content: center
}

.ewsc__logo img {
	width: 150px
}

.ewsc__container {
	margin-top: 100px;
	margin-bottom: 80px
}

.global__icons {
	display: flex;
	align-items: center;
	justify-content: center
}

.social__container {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	border-radius: 32px
}

.follow__text {
	font-weight: 500;
	font-size: 18px;
	color: #89898a;
	display: flex
}

.contract__btn,
.contract__label,
.section__title-membership,
.span__section,
.trade__btn {
	color: var(--main-color)
}

.social__icons {
	display: flex;
	flex-direction: wrap
}

.social__icons a {
	display: flex;
	justify-content: center;
	width: 16px;
	align-items: center;
	border-radius: 32px
}

button.trade__label,
button.trade__label1 {
	height: 130px;
	transition: transform .3s;
	cursor: pointer
}

.social__icons a:hover {
	display: flex;
	font-size: 20px;
	text-decoration: none;
	background-color: var(--golden-kiwi)
}

button.trade__label1:hover,
button.trade__label:hover,
button.utility__label:hover {
	background: linear-gradient(180deg, #059a6d, #01010100);
	transform: scale(1.1)
}

.global__icons,
.icon__list {
	display: flex;
	justify-content: center;
	align-items: center
}

.social__container,
.social__icons__container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%
}

.social__icons {
	justify-content: center;
}

.trade__container {
	margin-top: 30px;
	padding: 50px 0
}

.trade__content {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	flex-direction: column
}

.trade__boxes {
	display: flex;
	flex-direction: column;
	gap: 50px
}

button.trade__label {
	width: 130px;
	border-radius: 32px;
	background-image: linear-gradient(to bottom, #bde0e2, #8caeb4, #5f7f87, #34535d, #0a2a36);
	border: none
}

.our__partners__container,
.token__utility__box,
.tokencontract__container {
	margin-top: 50px
}

.section__title-membership {
	font-size: 50px;
	font-family: var(--typeface);
	background-image: linear-gradient(to right, var(--chalk-violet) 30%, var(--bleached-aqua) 40%, #37a586 60%);
	margin: 0;
}

.membership__contents {
	display: flex;
	margin-bottom: var(--mrg-top)
}

.membership__contents {
	flex-direction: column-reverse;
	align-items: center
}

h4.subtitle__membership {
	font-size: 22px;
	font-family: var(--grotesk-regular);
	margin-top: 45px;
	margin: 0;
	padding: 0;
}

button.hero__btn.color-1 {
	margin-top: 20px
}

.membership__text {
	font-family: var(--grotesk-semi-bold)
}

.membership__video {
	position: relative;
	display: none;
	margin-top: 50px;
	flex-direction: column;
	align-items: center
}

.membership__left {
	display: flex;
	flex-direction: column;
	font-family: var(--grotesk-regular);
	color: var(--main-color);
	font-size: 20px;
	width: 80%;
	align-items: center;
	margin: var(--mrg-top-mid);
}

.contract__subtitle,
.contract__title,
.section__subtitle__box,
.span__section,
.trade__btn {
	font-family: var(--grotesk-bold)
}

.backSphereCity,
.hero__left .hero__icon__container {
	display: none
}

#span__footer {
	text-align: center
}

.membership___right {
	width: 50%;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center
}

.getMembership {
	background: url(assets/img/Masks/Back_degvid-4.png) 0 0/contain no-repeat;
	width: auto;
	display: flex;
	position: relative
}

.getMembership video {
	width: 300px;
}


.form__badge {
	display: flex;
	border: none
}

button.trade__label1 {
	width: 130px;
	border-radius: 32px;
	background-image: linear-gradient(to bottom, #a284cc, 30%, #0a2a36);
	border: none
}

.trade__btn {
	margin-top: 10px;
	font-size: 18px;
	font-weight: 600
}

.trade__left {
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(2, 200px);
}

.trade__right {
	display: flex;
	flex-direction: row;
	gap: 40px;
	justify-content: center
}

.tokenomics__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 80px;
	padding: 20px;
}

.span__section {
	font-size: 24px;
	font-weight: 500;
}

.contract__label {
	margin-top: 10px;
	font-size: 16px;
	font-weight: 400;
	padding: 0 60px;
}

.contract__btn {
	display: flex;
	justify-content: center;
	font-size: 20px;
	font-weight: 900;
	margin-top: 25px;
	background: linear-gradient(to bottom, var(--button-hover) 15%, var(--bleached-aqua) 95%);
	padding: 15px;
	width: 11em;
	border-radius: 32px;
	border: none;
}

.contract__btn:hover {
	background: linear-gradient(to bottom, var(--button-hover) 15%, var(--legion-blue) 95%)
}

.footer__right,
.token__contract__right {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px
}

.token__contract__right img {
	border-radius: 18px;
	width: 250px
}

.contract__btn__container {
	display: flex;
	flex-direction: column;
	gap: 40px
}

.tokencontract__boxes {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px
}

.tokencontract__box1,
.tokencontract__box2 {
	gap: 10px;
	width: 88%;
	flex-direction: column;
	line-height: 10px;
	background-image: linear-gradient(to right, #009268, #007864, #005e5a, #004549, #002d34, #002d34, #002d34, #002d34, #004549, #005e5a, #007864, #009268);
	display: flex;
	padding: 30px 0;
	transition: transform .3s, border .3s
}

.tokencontract__box1 {
	border-radius: 24px;
	border: 2px solid transparent;
	align-items: center;
	justify-content: space-around
}

.tokencontract__box1:hover {
	border: 2px solid var(--golden-kiwi)
}

.contract__subtitle:hover {
	text-decoration: underline
}

.tokencontract__box2 {
	margin-top: 35px;
	border-radius: 24px;
	border: 2px solid transparent;
	align-items: center;
	justify-content: space-around
}

.tokencontract__box2:hover {
	border: 2px solid var(--golden-kiwi)
}

.contract__title {
	color: var(--bleached-aqua);
	font-size: 25px;
	font-weight: 900
}

.contract__subtitle {
	color: var(--main-color);
	font-size: 22px;
	font-weight: 900
}

.contract__subtitle__contract {
	font-size: 12px;
	color: var(--main-color);
	font-weight: 900
}

.contract__subtitle__contract a {
	font-size: 13px;
	color: var(--main-color)
}

.section__subtitle__box {
	font-size: 22px;
	color: var(--main-color);
	font-weight: 900;
	padding: 20px
}

.token__label,
.token__label-alt {
	color: var(--chalk-violet);
	font-weight: 600;
	font-family: var(--grotesk-bold)
}

.join__subtitle,
.join__title {
	padding: 10px 0
}

.token__utility__container {
	margin-top: 100px
}

.token__utility__content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	margin: 10px
}

.token__boxes {
	margin-bottom: 30px;
	max-width: 400px
}

.personalizado {
	width: 100%;
	height: auto;
	border-radius: 32px;
	z-index: -1
}

.backGorro {
	display: grid;
	-webkit-box-align: center;
	align-items: center;
	max-width: 300px;
	justify-items: center;
	margin: -15px auto;
	background: url(assets/img/Masks/Back_degvid-4.png) 0 0/contain no-repeat
}

.backAgnes {
	display: grid;
	-webkit-box-align: center;
	align-items: center;
	max-width: 350px;
	justify-items: center;
	margin: -15px auto;
	box-shadow: none;
	border: none;
	background: url(assets/img/Masks/Back_Agnes2.png) 0 0/contain;
}

.backAssistant,
.backBackground {
	display: grid;
	-webkit-box-align: center;
	margin: -15px auto
}

.backBackground {
	align-items: center;
	max-width: 300px;
	justify-items: center;
	background: url(assets/img/Masks/Back_Maskara.png) 0 0/contain no-repeat
}

.backAssistant {
	align-items: center;
	max-width: 380px;
	justify-items: center;
	background: url(assets/img/Masks/Back_Robot.png) 0 0/contain no-repeat
}

.backAmbassador,
.backRewards {
	display: grid;
	-webkit-box-align: center;
	max-width: 300px
}

.backAmbassador {
	align-items: center;
	justify-items: center;
	margin: 0 auto;
	background: url(assets/img/Masks/Back_degvid-4.png) 0 0/contain no-repeat
}

.backStaking,
.backSwap {
	-webkit-box-align: center;
	max-width: 300px;
	display: grid;
	margin: 0 auto
}

.backStaking {
	align-items: center;
	justify-items: center;
	background: url(assets/img/Masks/Back_Staking.png) 0 0/contain no-repeat
}

.backSwap {
	align-items: center;
	justify-items: center;
	background: url(assets/img/Masks/Back_Swap.png) 0 0/contain no-repeat
}

.backCastle {
	display: grid;
	-webkit-box-align: center;
	align-items: center;
	justify-items: center;
	margin: 0 auto;
	background: url(assets/img/Masks/Back_ENOCity.png) 0 0/contain no-repeat
}

.token__boxes img {
	border-radius: 30px;
	width: 220px;
	height: 250px;
	padding: 30px 0;
	/* background: linear-gradient(#0b192000 70%, #5bbcd5) */
}

.token__label {
	font-size: 26px
}

.token__label-alt {
	font-size: 35px;
	margin: 20px 0;
	line-height: 35px;
}

.token__utility__container__agnes {
	margin-top: 100px
}

.span__subtitle {
	color: var(--main-color);
	font-size: 16px;
	font-family: var(--grotesk-bold)
}

button.utility__label {
	width: 220px;
	height: 220px;
	border-radius: 32px;
	background-image: linear-gradient(to bottom, #bde0e2, #8caeb4, #5f7f87, #34535d, #0a2a36);
	border: none;
	transition: transform .3s
}

.utilityBtn {
	text-decoration: none
}

.join__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.join__span {
	margin-bottom: 30px;
	font-family: var(--grotesk-medium);
	font-weight: 700
}

.join__empower {
	font-family: var(--grotesk-medium);
	font-weight: 700;
	padding-bottom: 50px;
	color: var(--main-color);
	font-size: 20px;
	padding: 10px 0 0 0;
}

.join__span,
.join__subtitle,
.join__subtitle2 {
	font-family: var(--grotesk-regular)
}

.mapaimagen {
	margin-top: 50px;
	width: 100%;
	max-width: 800px;
	height: auto
}

.blurry__sphere-left,
.blurry__sphere-right {
	position: absolute;
	top: 50%;
	transform: translatey(-50%);
	height: 100%;
	filter: blur(100px);
	z-index: -1
}

.join__content {
	width: 85%;
	display: flex;
	border-radius: 32px;
	flex-direction: row-reverse;
	align-items: center;
	transition: transform .3s, border .3s;
	border: 2px solid transparent;
	background: url(./assets//img/VortexBN\ .png) 0 0/cover;
	background-color: var(--blue-vortex);
	justify-content: center;
	padding: 0;
	margin: 0;
}

.gridIcon,
.gridIcon:hover {
	-o-transition: .2s ease-in-out;
	-webkit-transition: .2s ease-in-out
}

.join__content:hover {
	border: 2px solid var(--golden-kiwi)
}

.blurry__sphere-right {
	right: -100px;
	width: 45%;
	background: linear-gradient(135deg, #360977, #02735e95, #fff);
	border-radius: 50%
}

.blurry__sphere-left {
	left: -100px;
	width: 90%;
	background: linear-gradient(200deg, #00766095, #0000007a, #00bbc6)
}

.join__subtitle2 {
	font-size: 18px;
	color: var(--main-color);
	margin-top: -15px
}

.join__left span,
.join__subtitle,
.join__title {
	color: var(--bleached-aqua)
}

.join__title {
	font-family: var(--typeface);
	font-size: 35px;
	margin: 0
}

.join__left {
	padding: 20px !important
}

.join__left span {
	font-size: 18px
}

.h2,
button {
	color: #fff
}

.h2 {
	font-weight: 900;
	font-size: 35px
}

.footer__box a,
.tokenRights {
	font-weight: 100;
	text-decoration: none;
}

.join__span,
.join__span2 {
	color: var(--main-color)
}

.join__right video {
	width: 150px;
	display: none
}

.join__span {
	font-family: var(--grotesk-regular);
	font-size: 20px;
	padding: 16px
}

.join__span2,
.join__span3 {
	font-family: var(--grotesk-regular);
	font-size: 24px
}

.join__span3 {
	color: var(--shady-glade);
	padding: 16px
}

.footer__link,
.title__core {
	font-family: var(--grotesk-bold)
}

.badge__container,
.core__team__container,
.our__partners__container {
	padding: 50px 0;
	text-align: center
}

.media__container {
	padding: 50px 0
}

.media__content img {
	margin-top: 30px;
	width: 50%
}

.core__boxes {
	display: grid;
	grid-template-columns: repeat(2, 3fr);
	padding: 20px;
	gap: 15px;
	justify-items: center;
	margin: auto;
	font-size: 16px
}

.core__box {
	background: linear-gradient(to bottom, #62e7ee 14%, #e3e3e300 75%);
	width: 100px;
	border-radius: 50%;
	overflow: hidden
}

.form__contact-form,
.gridIcon {
	background-color: var(--main-color)
}

.core__box img {
	border-radius: 28px;
	width: 100px;
	height: auto
}

.title__core {
	font-size: 18px;
	line-height: 20px;
	color: var(--main-color);
	margin: 5px
}

.span__core {
	font-size: 15px;
	color: var(--main-color)
}

.gridIcon {
	color: var(--black-eno);
	border-radius: 32px;
	height: 15px;
	width: 15px;
	padding: 10px
}

.gridIcon:hover {
	background-color: #64d4e8;
	transition: .2s ease-in-out
}

.footer__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 80px
}

.social__btn__footer a {
	color: #d9d9d9;
	margin: 0 15px;
	font-size: 25px
}

.footer__link {
	color: var(--bleached-aqua);
	font-size: 18px
}

.footer__box,
.footer__box a,
.hero__title-alt,
.name__link,
.section__title-alt,
.tokenRights {
	color: var(--main-color)
}

.name__link {
	font-size: 16px;
	font-family: var(--grotesk-semi-bold)
}

.form-group {
	margin-bottom: 20px
}

label {
	display: block;
	margin-bottom: 5px
}

.footer__left,
.input-with-button {
	display: flex
}

input[type=text] {
	flex: 1;
	padding: 10px;
	border: 1px solid #ccc;
	font-size: 16px
}

button {
	padding: 10px 20px;
	padding-top: 15px !important;
	background-color: var(--black-eno);
	font-family: var(--grotesk-bold);
	border: none;
	border-radius: 0 5px 5px 0;
	font-size: 16px;
	cursor: pointer
}

input[type=text]::placeholder {
	color: #a0a0a0
}

.section__subtitle__footer {
	font-size: 26px;
	font-family: var(--grotesk-bold);
	color: var(--main-color)
}

.social__footer {
	display: flex;
	margin-top: .5rem;
	gap: 3rem
}

.footer__logo {
	display: flex;
	width: 200px
}

.rightsReserved {
	margin-top: 30px
}

.politicsPrivacy {
	display: flex;
	flex-direction: row;
	gap: 30px
}

.footer__container {
	display: flex;
	gap: 25px;
	height: 65px;
	align-items: center;
	justify-content: center;
	flex-direction: row
}

.footer__box {
	font-size: 14px
}

.tokenRights {
	font-size: 9px;
	margin: 20px 0
}

.form__contact-form {
	display: flex;
	border-radius: 32px
}

.data__contact-form {
	display: flex;
	margin: 3px
}

.submit__contact-form {
	border-radius: 32px
}

input[type=text] {
	border: transparent;
	border-radius: 32px;
	margin-top: 5px
}

.blockchain__titles {
	display: flex;
	flex-direction: column;
	margin-top: 100px;
	margin-bottom: -50px
}

.section__title-alt {
	font-family: var(--grotesk-regular);
	font-size: 25px;
	margin-top: 100px;
	line-height: 10px
}

.hero__title-alt {
	font-size: 50px;
	font-family: var(--typeface);
	margin-top: 10px;
	background-image: linear-gradient(to right, var(--chalk-violet) 30%, var(--bleached-aqua) 40%, var(--contract-color) 60%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	padding: 1px
}

.join__span-alt,
.ourParnets__text {
	color: var(--main-color);
	font-family: var(--grotesk-regular)
}

.join__span-alt {
	font-size: 18px;
	font-weight: 700;
	padding: 0 50px
}

.section__title h2 {
	margin: 0
}

.ourParnets__text {
	padding: 20px
}

img.mapaimagen {
	width: 80%
}

.video__content {
	margin-top: 150px;
}

.btn-alternativeColor {
	background-color: var(--shady-glade);
	border-radius: 32px;
	font-size: 16px;
}

.btn-alternativeColor:hover {
	background-color: var(--button-hover);
}

@media (min-width:1024px) {

	.whyContent {
		margin-bottom: 100px;
	}

	.join__empower {
		font-family: var(--grotesk-medium);
		font-weight: 700;
		padding-bottom: 50px;
		color: var(--main-color);
		font-size: 20px;
		padding: 10px 0 0 0;
	}

	.empower {
		padding: 0px 0px 150px;
	}

	.hero__paragraph,
	.hero__title,
	.span__hero {
		color: var(--main-color)
	}

	.hero__title,
	.hero__title-alt,
	.section__title {
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent
	}

	.hero__title,
	.hero__title-alt,
	.section__title,
	.section__title-membership {
		-webkit-text-fill-color: transparent
	}

	.hero__container {
		padding: 50px 0;
		display: flex;
		justify-content: center
	}

	.hero__content {
		display: flex;
		flex-direction: row;
		align-items: center
	}

	.hero__logo {
		width: 250px;
		margin-bottom: 30px
	}

	.hero__paragraph {
		font-size: 25px;
		font-weight: 900;
		line-height: 10px
	}

	.hero__title {
		font-size: 75px;
		font-weight: 400;
		text-align: left;
		padding: 25px 0;
		margin: 0;
		background-image: linear-gradient(to right, var(--chalk-violet) 30%, var(--bleached-aqua) 40%, var(--contract-color) 60%)
	}

	.span__hero {
		font-size: 20px;
		font-family: var(--grotesk-semi-bold);
		margin-bottom: 40px;
		padding: 0
	}

	.hero__left {
		display: flex;
		flex-direction: column;
		text-align: left;
		margin-left: 120px;
		align-items: flex-start
	}

	.hero__right img {
		width: 800px
	}

	.hero__btn {
		cursor: pointer;
		border: none;
		background-size: 300% 100%;
		transition: .4s ease-in-out
	}

	.hero__btn__container {
		display: flex;
		flex-direction: row;
		margin: -10px
	}

	.ewsc__logo img,
	.footer__logo {
		width: 250px
	}

	.contract__address {
		color: var(--chalk-violet);
		font-size: 17px;
		font-weight: 700;
		text-align: center
	}

	.contract__link {
		color: #fff;
		font-size: 15px;
		text-decoration: underline
	}

	.social__icons a,
	.social__icons a:hover,
	.utilityBtn {
		text-decoration: none
	}

	.warpIcon,
	.MarketIcon,
	.CoinGecko {
		margin-top: 5px;
		width: 25px;
	}

	.Zealy {
		padding-top: 4px;
		width: 25px;
	}

	.section__title {
		background-image: linear-gradient(to right, var(--chalk-violet) 30%, var(--bleached-aqua) 60%, #6ec1b2 10%);
		display: inline;
		font-weight: 900;
		font-size: 35px;
		font-family: var(--grotesk-bold);
		line-height: 42px;
		text-align: center
	}

	section.video__container {
		display: flex;
		justify-content: center;
	}

	.video__content {
		width: 100%;
		margin-top: 150px;
	}

	.token__utility__container__agnes {
		margin-top: 150px
	}

	.our__partners__container,
	.tokenomics__container {
		margin-top: 100px;
		padding: 1px 0
	}

	.core__team__container,
	.media__container {
		margin-top: 50px
	}

	.blockchain__titles {
		display: flex;
		flex-direction: column
	}

	.section__title-alt {
		color: var(--main-color);
		font-family: var(--grotesk-regular);
		font-size: 35px;
		margin: 0;
		line-height: 10px
	}

	.hero__title-alt {
		color: var(--main-color);
		font-size: 75px;
		font-family: var(--typeface);
		background-image: linear-gradient(to right, var(--chalk-violet) 30%, var(--bleached-aqua) 40%, var(--contract-color) 60%);
		padding: 10px;
		margin: 0;
	}

	.join__span {
		font-family: var(--grotesk-regular);
	}

	.join__span-alt {
		color: var(--main-color);
		font-family: var(--grotesk-regular);
		font-size: 25px;
		font-weight: 700;
		padding: 0 50px
	}

	.section__title h2 {
		margin: 0
	}

	.section__subtitle__box {
		font-size: 27px;
		padding: 0;
		margin: 0
	}

	.global__icons {
		display: flex;
		align-items: center;
		justify-content: center
	}

	.social__container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		border-radius: 32px
	}

	.follow__text {
		font-weight: 500;
		font-size: 18px;
		color: #89898a;
		display: flex
	}

	.contract__subtitle,
	.contract__title {
		font-weight: 900;
		font-family: var(--grotesk-bold)
	}

	.social__icons {
		display: flex;
		flex-direction: wrap;
		gap: 20px;
		font-size: 20px;
		color: #89898a;
	}

	.social__icons a {
		display: flex;
		font-size: 20px;
		color: #000;
		background-color: #bde0e2;
		padding: 5px;
		border-radius: 32px;
		width: 25px;
		height: 25px;
	}

	.social__icons a:hover {
		display: flex;
		background-color: var(--golden-kiwi)
	}

	button.trade__label1:hover,
	button.trade__label:hover,
	button.utility__label:hover {
		background: linear-gradient(180deg, #059a6d, #01010100);
		transform: scale(1.1)
	}

	.social__icons__container {
		display: flex;
		gap: 10px;
		text-decoration: none
	}

	.hero__icon__container,
	.membership__video-modile {
		display: none
	}

	.video-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%
	}

	.youtube-video {
		transition: transform .3s, border .3s;
		border: 2px solid transparent;
		width: 60%;
		height: 480px;
		-webkit-box-shadow: -1px 9px 42px 8px #0b3d3d;
		-moz-box-shadow: -1px 9px 42px 8px #0b3d3d;
		box-shadow: -1px 9px 42px 8px #0b3d3d
	}

	.youtube-video:hover {
		border: 2px solid var(--golden-kiwi)
	}

	.tokencontract__container {
		margin-top: 50px;
		padding: 50px 0
	}

	.tokencontract__boxes {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px
	}

	.token__box {
		padding: 10px 50px
	}

	.tokencontract__box1,
	.tokencontract__box2 {
		gap: 10px;
		width: 88%;
		flex-direction: row;
		padding: 30px 0;
		background-image: linear-gradient(to right, #009268, #007864, #005e5a, #004549, #002d34, #002d34, #002d34, #002d34, #004549, #005e5a, #007864, #009268);
		display: flex;
		transition: transform .3s, border .3s
	}

	.token__boxes video {
		display: inline
	}

	.tokencontract__box1 {
		border-radius: 24px;
		border: 2px solid transparent;
		justify-content: space-around;
		margin-top: 50px
	}

	.tokencontract__box1:hover {
		border: 2px solid var(--golden-kiwi)
	}

	.tokencontract__box2 {
		margin-top: 35px;
		border-radius: 24px;
		border: 2px solid transparent;
		justify-content: space-around
	}

	.tokencontract__box2:hover {
		border: 2px solid var(--golden-kiwi)
	}

	.contract__subtitle:hover {
		color: var(--golden-kiwi);
		text-decoration: underline
	}

	.contract__title {
		color: var(--bleached-aqua);
		font-size: 28px
	}

	.contract__subtitle {
		color: var(--main-color);
		font-size: 12
	}

	.contract__subtitle__contract a {
		font-size: 18px;
		color: var(--main-color)
	}

	.token__content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 95%
	}

	.token__contract__right img {
		border-radius: 18px;
		width: 550px
	}

	.contract__label {
		color: var(--main-color);
		margin-top: 10px;
		font-size: 16px;
		font-weight: 200;
		padding: 0 138px;
		text-align: left
	}

	.join__left span,
	.trade__btn {
		font-size: 18px
	}

	.contract__btn__container {
		display: flex;
		flex-direction: row;
		gap: 40px
	}

	.trade__content {
		display: flex;
		flex-direction: row;
		margin-top: 30px
	}

	.join__container,
	.trade__boxes {
		flex-direction: column;
		display: flex
	}

	.trade__boxes {
		gap: 60px
	}

	button.trade__label {
		width: 180px;
		height: 180px;
		border-radius: 32px;
		background-image: linear-gradient(to bottom, #bde0e2, #8caeb4, #5f7f87, #34535d, #0a2a36);
		border: none;
		transition: transform .3s
	}

	button.trade__label1,
	button.utility__label {
		border-radius: 32px;
		transition: transform .3s
	}

	button.trade__label1 {
		width: 180px;
		height: 180px;
		cursor: pointer;
		background-image: linear-gradient(to bottom, #a284cc, 30%, #0a2a36);
		border: none
	}

	button.trade__label1:hover {
		transition: transform .3s
	}

	.trade__btn {
		color: var(--main-color);
		font-family: var(--grotesk-bold);
		margin-top: 10px;
		font-weight: 600
	}

	.join__span,
	.join__span2 {
		color: var(--main-color);
		font-size: 24px;
		font-family: var(--grotesk-regular)
	}

	.trade__left,
	.trade__right {
		display: flex;
		justify-content: center;
		gap: 50px
	}

	.token__utility__box {
		display: flex;
		justify-content: center;
		gap: 20px;
		flex-wrap: wrap
	}

	.token__utility__container {
		margin-top: var(--mrg-top-mid)
	}

	button.utility__label {
		width: 230px;
		height: 230px;
		background-image: linear-gradient(to top, #5fc9dd, #040f13);
		border: none
	}

	.join__span {
		padding: 0 250px;
		margin-bottom: 25px
	}

	.join__subtitle,
	.join__subtitle2 {
		color: var(--main-color);
		font-size: 19px
	}

	.join__content {
		width: 65%;
		display: flex;
		margin: 0;
		border-radius: 32px;
		flex-direction: row-reverse;
		align-items: center;
		transition: transform .3s, border .3s;
		border: 2px solid transparent;
		background: var(--blue-vortex)
	}

	.media__content {
		margin: 50px 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 20px;
		justify-content: center
	}

	.optimized-image {
		background-color: transparent;
	}

	.optimized-image:hover {
		transform: scale(1.1);
		transition: .3s ease-out;
		background-color: transparent;
		cursor: pointer;
	}

	.join__content:hover {
		border: 2px solid var(--golden-kiwi)
	}

	.join__container {
		align-items: center
	}

	.join__subtitle2 {
		font-family: var(--grotesk-regular)
	}

	.join__left span,
	.join__subtitle,
	.join__title {
		color: var(--bleached-aqua)
	}

	.join__title {
		font-family: var(--typeface);
		font-size: 50px;
		margin: 0
	}

	.span__core,
	.title__core {
		color: var(--main-color)
	}

	.join__left {
		border: none;
		background-image: none;
		width: 100%;
		display: flex;
		text-align: left;
		flex-direction: column;
		align-items: flex-start;
		padding: 0
	}

	.centerLogo__image,
	.input-with-button,
	.join__right {
		display: flex
	}

	.join__right video {
		width: 400px;
		display: flex;
		border-radius: 32px
	}

	section.meet__eno__container {
		margin-top: 8em
	}

	.core__boxes {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 1px;
		justify-items: center;
		margin: auto;
		font-size: 16px
	}

	.core__box {
		background: linear-gradient(to bottom, #62e7ee 14%, #e3e3e300 75%);
		width: 180px;
		border-radius: 50%;
		overflow: hidden;
		transition: transform .3s;
		-o-transition: .2s ease-in-out
	}

	.core__box img {
		border-radius: 32px;
		width: 180px;
		height: auto
	}

	.title__core {
		font-family: var(--grotesk-bold);
		line-height: 20px;
		margin: 5px
	}

	.gridIcon {
		color: var(--black-eno);
		background-color: var(--main-color);
		border-radius: 32px;
		height: 15px;
		width: 15px;
		-o-transition: .2s ease-in-out;
		-webkit-transition: .2s ease-in-out;
		-moz-transition: .2s ease-in-out;
		transition: .2s ease-in-out
	}

	.gridIcon:hover {
		background-color: #64d4e8;
		-o-transition: .2s ease-in-out;
		-webkit-transition: .2s ease-in-out;
		transition: .2s ease-in-out
	}

	.btn.btn-outline-secondary,
	button {
		background-color: var(--black-eno);
		font-family: var(--grotesk-bold);
		color: #fff;
		cursor: pointer
	}

	.footer__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start
	}

	#span__footer {
		text-align: left
	}

	.social__btn__footer a {
		color: #d9d9d9;
		margin: 0 15px;
		font-size: 25px;
	}

	.footer__link {
		color: var(--bleached-aqua);
		font-family: var(--grotesk-bold);
		font-size: 22px;
	}

	.name__link {
		color: var(--main-color);
		font-size: 18px;
		font-family: var(--grotesk-semi-bold);
		text-decoration: none;
	}

	.btn.btn-outline-secondary {
		height: 2.5rem;
		width: 7.9rem;
		font-size: 16px;
		border: none;
		border-radius: 0 5px 5px 0;
	}

	.btn.btn-outline-secondary:hover {
		background-color: #040f1380;
	}

	.footer__right {
		width: none;
		display: flex;
		margin-top: 40px;
		flex-direction: column;
		align-items: flex-end;
	}

	.section__subtitle__footer {
		font-size: 25px;
		color: var(--main-color);
	}

	.btn:focus,
	.input:focus,
	input:focus {
		outline: 0
	}

	.social__footer {
		display: flex;
		margin-top: .5rem;
		gap: 3rem
	}

	.footer__content {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		text-align: left;
		margin: 50px;
		justify-content: space-around
	}

	.form-group {
		margin-bottom: 20px
	}

	label {
		display: block;
		margin-bottom: 5px
	}

	input[type=text] {
		flex: 1;
		padding: 10px;
		border: 1px solid #ccc;
		font-size: 16px
	}

	button {
		padding: 10px 20px;
		border: none;
		border-radius: 0 5px 5px 0;
		font-size: 16px
	}

	button:hover {
		background-color: var(--blue-vortex)
	}

	button::placeholder {
		color: var(--golden-kiwi)
	}

	.section__title-membership h2 {
		margin: 0;
	}

	.section__title-membership {
		background-image: linear-gradient(to right, #a298b0 30%, var(--bleached-aqua) 40%, var(--contract-color) 60%);
		-webkit-background-clip: text;
		display: inline;
		font-weight: 900;
		font-size: 40px;
		letter-spacing: 1.5px;
		font-family: var(--typeface);
		line-height: 42px;
		text-align: left;
		margin: 0;
		width: 100%
	}

	.membership__left,
	.ourPartners__left,
	.span__subtitle-membership {
		font-family: var(--grotesk-regular)
	}

	.membership__contents {
		display: flex;
		flex-direction: row;
		justify-content: space-between
	}

	.subtitle__badge,
	.subtitle__membership {
		color: var(--chalk-violet);
		margin: 0
	}

	.membership__left,
	.ourPartners__left,
	.tokenRights {
		color: var(--main-color)
	}

	.span__subtitle-membership {
		font-family: var(--grotesk-regular);
		font-size: 22px;
		margin-top: 15px;
	}

	.membership__video {
		display: flex;
		margin-top: 100px;
		align-items: center;
		padding: 0 250px;
		text-align: left;
		justify-content: space-between;
		flex-direction: column
	}

	.membership__left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		font-size: 22px;
		width: 50%
	}

	.getMembership {
		display: flex;
		align-items: center;
		position: relative;
		background: url(assets/img/Masks/Back_degvid-4.png) 0 0/contain no-repeat;
		width: auto;
		max-width: 450px;
	}

	.membership___right {
		width: 40%;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center
	}

	.membership___right video {
		border-radius: 32px;
		display: flex;
		margin: 0;
		width: 450px
	}

	.membership___right img {
		display: block;
		margin: 0 auto;
		width: 450px
	}

	.form__badge {
		display: flex;
		border: none
	}

	.backGorro,
	.backSphereCity {
		display: grid;
		background: url(assets/img/Masks/Back_degvid-4.png) 0 0/contain no-repeat
	}

	.responsive-video {
		width: 42em
	}

	.backGorro {
		-webkit-box-align: center;
		align-items: center;
		max-width: 380px;
		justify-items: center;
		margin: -15px auto
	}

	.backAgnes {
		-webkit-box-align: center;
		align-items: center;
		max-width: 380px;
		justify-items: center;
	}

	.backSphereCity {
		-webkit-box-align: center;
		align-items: center;
		width: 650px;
		justify-items: center
	}

	.ourPartners-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 100px
	}

	.ourPartners__left {
		width: 40%;
		text-align: left;
		font-size: 21px;
		padding-right: 3%
	}

	.ourPartners__right {
		width: 60%
	}

	.tokenRights {
		font-size: 9px;
		font-weight: 100;
		margin-bottom: 15px
	}

	.form__contact-form {
		background-color: var(--main-color);
		display: flex;
		border-radius: 32px
	}

	.data__contact-form {
		display: flex;
		margin: 3px
	}

	.submit__contact-form {
		border-radius: 32px
	}

	input[type=text] {
		border: transparent;
		border-radius: 32px;
		margin-top: 5px
	}

	.btn-alternativeColor {
		background-color: var(--shady-glade);
		border-radius: 32px;
		font-size: 20px;
	}

	.btn-alternativeColor:hover {
		background-color: var(--button-hover);
	}

}

@media (max-width:767px) {
	.hero__right img {
		display: none
	}
}

@media (min-width:768px) and (max-width:1024px) {

	.blurry__sphere-left,
	.blurry__sphere-right {
		width: 250px;
		height: 250px;
		filter: blur(60px)
	}

	.blurry__sphere-left {
		left: -50px
	}

	.blurry__sphere-right {
		right: -50px
	}
}

@media (min-width:1023px) and (max-width:1200px) {
	.membership__video {
		padding: 0 120px
	}
}

@media only screen and (min-width: 1900px) {
	.hero__content {
		width: 90%;
		max-width: 1200px;
	}

	.membership__contents {
		width: 70%;
		max-width: 1200px;
	}

	.join__content {
		width: 50%;
		max-width: 1200px;
	}

	.video__content {
		max-width: 1200px;
	}

	.backSphereCity {
		-webkit-box-align: center;
		align-items: center;
		width: 550px;
		justify-items: center
	}
}